



















































































@import '../../utilities/config';

.m-confirmation-popover {
  position: relative;
  display: inline-block;
  &__title {
    font-size: 17px;
    font-weight: 500;
  }
  &__text {
  }
  &__content {
    max-width: calc(100vw - 60px);
  }
  &__footer {
    max-width: calc(100vw - 60px);
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    :last-child {
      margin-right: 0;
    }
  }
  &__btn {
    margin-right: 15px;
  }

  @media screen and (min-width: breakpoint-min('md')) {
    &__title {
      max-width: 400px;
    }
    &__content {
      max-width: 400px;
    }
  }
}
