



























































































































@import '../../utilities/config';

.m-grid {
  margin: 15px 0;
  overflow-x: auto;

  .btn-download {
    margin-top: -10px;
  }
  .form-group {
    margin-bottom: 0;
    display: inline-block;
  }
  &__actions {
    display: flex;
    max-width: 180px;
    flex-wrap: wrap;
  }
  &__item {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
