@import '../config';
@import 'node_modules/bootstrap/scss/tables';

@media screen {
  .table {
    font-size: 14px;
    width: 99.9%;
    th {
      font-weight: 500;
    }

    th.active {
      color: gray('gray-750');
    }

    thead {
      th {
        color: gray('gray-400');
      }
    }
    .vcenter {
      vertical-align: middle;
    }
    .sortable {
      .arrow {
        display: inline-block;
        vertical-align: middle;
        height: 12px;
        width: 4px;
        margin-left: 5px;

        position: relative;
        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-bottom: 5px solid gray('gray-700');
          top: 0;
          left: 0;
          opacity: 0.3;
        }
        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 5px solid gray('gray-700');
          bottom: 0;
          left: 0;
          opacity: 0.3;
        }
        &.asc {
          &:before {
            opacity: 1;
          }
        }
        &.desc {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    .align-right {
      text-align: right;
    }

    .valign-center {
      vertical-align: middle;
    }

    &.table-hover {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
    &.table-filter {
      thead {
        th.sortable {
          cursor: pointer;
        }
      }
    }

    &.table-bordered {
      border: 0;
      & thead {
        th,
        td {
          border-top: 0;
          border-left: 0;
          border-right: 0;
          white-space: nowrap;
        }
      }
      tbody {
        th {
          border: 0;
        }
        td {
          border: 0;
        }
        tr {
          border: $table-border-width solid $table-border-color;
        }
      }
    }
  }
}
