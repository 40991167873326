@import '../config';

.page {
  &__header {
    border-bottom: 1px solid gray('gray-150');
    &__content {
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
      //padding-top: 40px;
      //padding-bottom: 40px;
      padding-top: 25px;
      padding-bottom: 25px;
    }
    &__title {
      font-size: 30px;
      color: gray('gray-700');

      &::after {
        content: '';
        display: inline-block;
        height: 24px;
        width: 24px;
        //background-color: red;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: 10px;
      }
    }
    &__tab {
      display: flex;
      margin-bottom: -1px;
      height: 45px;
      &--overlap {
        //margin-top: -45px;
        margin-top: -44px;
      }
      &__item {
        cursor: pointer;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
        padding: 10px 15px;
        border: 1px solid transparent;
        border-bottom: 1px solid gray('gray-150');
        font-size: 15px;
        font-weight: 500;
        color: gray('gray-700');
        background-color: gray('gray-150');
        margin-right: 10px;
        &--active {
          border: 1px solid gray('gray-150');
          border-bottom: 1px solid gray('gray-100');
          background-color: gray('gray-100');
          color: gray('gray-750');
        }
      }
    }
  }

  &__body {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: gray('gray-100');
    overflow: hidden;
    position: relative;

    &--hastab {
      padding-bottom: 85px;
    }

    &__image {
      background-image: url(../../assets/backgrounds/orange_vest_guy-50.jpg);
      //background-image: url(../../assets/backgrounds/orange_vest_guy--blur.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 110%;
      width: 110%;
      top: -5%;
      left: -5%;
      position: absolute;
      filter: blur(5px);
      //background-image: url(../../assets/backgrounds/pierre.jpg);
    }

    &--minheight {
      min-height: calc(100vh - 260px);
    }
    &--white {
      background-color: gray('white');
    }

    &--nopadding {
      padding-top: 0;
      padding-bottom: 0;
    }
    &--nopaddingtop {
      padding-top: 0;
    }
    &--nopaddingbottom {
      padding-bottom: 0;
    }
    &__col {
      :first-child {
        margin-top: 0;
      }
    }
    & + & {
      border-top: 1px solid gray('gray-150');
    }

    h2 {
      font-size: 23px;
      margin-top: 50px;
    }
    h3 {
      font-size: 17px;
      font-weight: 500; //font-weight: 400;;
      margin-top: 35px;
    }
    h3.bolder {
      font-size: 19px;
      font-weight: 500;
      margin-top: 45px;
      margin-bottom: 25px;
    }

    blockquote {
      //background-color: $white;
    }

    &__btns {
      text-align: right;
    }
    // Jedi: to be deleted after boxes refactor
    &__box {
      border-radius: 7px;
      border: 1px solid gray('gray-150');
      //padding: 35px 10px;
      //height: 100%;
      margin: 15px 0;
    }
  }
  &__col {
    &__separator {
      border-left: 1px solid gray('gray-150');
      padding: 40px 30px;

      &:first-child {
        border-left: 0;
        padding-left: 15px;
      }
      &:last-child {
        padding-right: 15px;
      }
    }
  }
  &__row {
    &__separator {
    }
  }
}
.page__body__box {
  .page__row__separator {
    .page__col__separator {
      &:first-child {
        border-left: 0;
        padding-left: 45px;
      }
      &:last-child {
        padding-right: 45px;
      }
    }
  }
}

@media screen and (min-width: breakpoint-min('sm')) {
}

@media screen and (min-width: breakpoint-min('md')) {
}

@media screen and (min-width: breakpoint-min('lg')) {
}
